<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { roomTypeEnum } from "@/components/enums.js";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Otel Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Oda Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        }, 
        {
          text: "Oda Yönetimi",
          href: "/room",
        },
        {
          text: "Oda Ekle",
          active: true,
        },
      ],
      values: {
        room_name: "",
        room_type: "",
      },
      roomTypeEnum,
      isButton: false,
    };
  },
  methods: {
    onSubmit() {
      this.isButton = true;
      const api_url = process.env.VUE_APP_BASEURL + "/room";
      const data = {
        room_name: this.values.room_name,
        room_type: this.values.room_type.value
      }
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/room");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="brand_name">Oda Adı:</label>
                    <input v-model="values.room_name" type="text" class="form-control" id="brand_name" required />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="roomType">Oda Tipi</label>
                    <multiselect v-model="values.room_type" :options="roomTypeEnum" label="text">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4" :disabled="isButton">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
